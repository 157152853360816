<template>
	<div>
		<div class="header">
			<div class="header__appcreate">
			</div>
			<div class="header__language">
				<select class="black--text ma-0" ><!--v-model="$i18n.locale"-->
					<option value="ru" selected>🇷🇺 Русский</option>
					<option value="kg">🇰🇬 Кыргызский</option>
				</select>
			</div>
			<div class="header__bookmarks">
				<v-btn solo>
					<v-icon color="#003df3">mdi mdi-bookmark-plus</v-icon>
				</v-btn>
			</div>
			<div class="header__notification">
				
				<v-menu
					offset-y
					left>
				<template v-slot:activator="{ attrs, on }">
					<v-btn
					class="black--text ma-0"
					v-bind="attrs"
					v-on="on"
					>
					<v-badge
						
						class="header__notification__badge"
						:content="notificationCount"
						:value="1"
						color="#fdca40"
						overlap
					>
						
						<svg class="header__notification__icon" xmlns="http://www.w3.org/2000/svg" width="17.876" height="20.143" viewBox="0 0 15.876 18.143">
						<path id="bell" d="M15.57,12.838C14.885,12.1,13.6,11,13.6,7.371a5.6,5.6,0,0,0-4.534-5.5V1.134a1.133,1.133,0,1,0-2.267,0v.738a5.6,5.6,0,0,0-4.534,5.5C2.27,11,.989,12.1.3,12.838A1.107,1.107,0,0,0,0,13.608a1.135,1.135,0,0,0,1.138,1.134h13.6a1.135,1.135,0,0,0,1.138-1.134,1.107,1.107,0,0,0-.305-.769Zm-13.177.2A8.874,8.874,0,0,0,3.97,7.391c0-.007,0-.013,0-.021a3.969,3.969,0,1,1,7.938,0c0,.007,0,.013,0,.021a8.872,8.872,0,0,0,1.578,5.649Zm5.545,5.1A2.268,2.268,0,0,0,10.2,15.875H5.67A2.268,2.268,0,0,0,7.937,18.143Z" transform="translate(0.001)" fill="#636d73"/>
						</svg>
					</v-badge>
					</v-btn>
				</template>
				<v-card class="header__notification__list">
					<v-list >
					
						<v-list-item
						
						>
						<div  class="header__notification__list__icon">
							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25" height="25" viewBox="0 0 20 20">
								<defs>
									<clipPath id="clip-path">
									<path id="_Icon_Сolor" data-name="🎨 Icon Сolor" d="M10,20A10,10,0,1,1,20,10,10.011,10.011,0,0,1,10,20ZM6.5,8.893a1,1,0,0,0-.787,1.616l2.432,3.107A1,1,0,0,0,8.931,14h.007a1,1,0,0,0,.789-.4l4.568-6A1,1,0,1,0,12.7,6.394L8.92,11.363,7.287,9.277A.992.992,0,0,0,6.5,8.893Z" transform="translate(2 2)" fill="#b1b6b9"/>
									</clipPath>
								</defs>
								<g id="_01_Icons_Filled_arrow-circle-down-fill" data-name="01) Icons/Filled/arrow-circle-down-fill" transform="translate(-2 -2)">
									<g id="Сгруппировать_313" data-name="Сгруппировать 313">
									<path id="_Icon_Сolor-2" data-name="🎨 Icon Сolor" d="M10,20A10,10,0,1,1,20,10,10.011,10.011,0,0,1,10,20ZM6.5,8.893a1,1,0,0,0-.787,1.616l2.432,3.107A1,1,0,0,0,8.931,14h.007a1,1,0,0,0,.789-.4l4.568-6A1,1,0,1,0,12.7,6.394L8.92,11.363,7.287,9.277A.992.992,0,0,0,6.5,8.893Z" transform="translate(2 2)" fill="#159d00"/>
									</g>
								</g>
							</svg>
						</div>
						<div  class="header__notification__list__icon">
							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25" height="25" viewBox="0 0 20 20">
								<defs>
									<clipPath id="clip-path">
									<path id="_Icon_Сolor" data-name="🎨 Icon Сolor" d="M10,20A10,10,0,1,1,20,10,10.011,10.011,0,0,1,10,20ZM6.5,8.893a1,1,0,0,0-.787,1.616l2.432,3.107A1,1,0,0,0,8.931,14h.007a1,1,0,0,0,.789-.4l4.568-6A1,1,0,1,0,12.7,6.394L8.92,11.363,7.287,9.277A.992.992,0,0,0,6.5,8.893Z" transform="translate(2 2)" fill="#b1b6b9"/>
									</clipPath>
								</defs>
								<g id="_01_Icons_Filled_arrow-circle-down-fill" data-name="01) Icons/Filled/arrow-circle-down-fill" transform="translate(-2 -2)">
									<g id="Сгруппировать_313" data-name="Сгруппировать 313">
									<path id="_Icon_Сolor-2" data-name="🎨 Icon Сolor" d="M10,20A10,10,0,1,1,20,10,10.011,10.011,0,0,1,10,20Zm0-8.588h0l3.535,3.534a1,1,0,1,0,1.414-1.414L11.414,10l3.535-3.536a1,1,0,1,0-1.414-1.414L10,8.584,6.464,5.048A1,1,0,1,0,5.05,6.462L8.585,10,5.05,13.533a1,1,0,1,0,1.414,1.414L10,11.413Z" transform="translate(2 2)" fill="#ff4343"/>
									</g>
								</g>
							</svg>
						</div>
						<div  class="header__notification__list__icon">
							<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
								<path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.31 7.526c-.099-.807.528-1.526 1.348-1.526.771 0 1.377.676 1.28 1.451l-.757 6.053c-.035.283-.276.496-.561.496s-.526-.213-.562-.496l-.748-5.978zm1.31 10.724c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z" fill="#ffa143"/>
							</svg>
						</div>
						


						


						<v-list-item-content class="header__notification__list__content">
							<v-row>
								<v-col md="7" sm="7">
									
								</v-col>
								<v-col md="5" sm="5" align="end">	
									<h6 class="header__notification__list__content__title__time">fdbcdfcv</h6>
								</v-col>
							</v-row>
							
							<div class="header__notification__list__content__line" >
								<svg xmlns="http://www.w3.org/2000/svg" width="323" height="1" viewBox="0 0 323 1">
									<rect id="Rectangle" width="323" height="1" fill="#eff4fa"/>
								</svg>
							</div>
							

						</v-list-item-content>
						




						</v-list-item>
						
					</v-list>
					<v-btn class="header__notification__list_btn" width="100%" @click="readAllNotifications">Пометить как прочитанное</v-btn>
				</v-card>
				
				</v-menu>
			</div>
			<div class="header__avatar">
				<v-avatar class="avatar ma-0" style="border: 1px solid orange;">
					<img
						class="avatar"
						src="../../assets/logo_1.png"
						alt="Red dot"
					>
				</v-avatar>
			</div>
			<!--<div v-if="!avatarHas" class="header__avatar">
				<v-avatar class="avatar ma-0">
					<img
						class="avatar"
						src="../../assets/male.png"
						alt="Red dot"
					>
				</v-avatar>
			</div>-->
			<div class="header__user">
				<v-menu
					offset-y>
				<template v-slot:activator="{ attrs, on }">
					<v-btn
					class="black--text ma-0"
					v-bind="attrs"
					v-on="on"
					>
					{{ loggedIn.title }}
					</v-btn>
				</template>
				<v-list>
					<v-list-item
					offset-y
					>
					<v-row>
						<v-col md="12" sm="12">
							<router-link :to="{path:'/doctor-page/profile'}"><v-list-item-title>Профиль</v-list-item-title></router-link>
						</v-col>
						<v-col md="12" sm="12">
							<v-dialog
						v-model="changePass"
						width="500"
						>
						<template v-slot:activator="{ on, attrs }">
							<v-list-item-title v-bind="attrs"
							v-on="on">Изменить пароль</v-list-item-title>
							
						</template>

						<v-card>
							<v-card-title class="header1__modal headline">
								Изменить пароль
							</v-card-title>

							<v-card-text>
								<v-row>
									<v-col md="12" sm="12">
										<v-text-field solo
											v-model="password"
											label="Введите новый пароль"
											name="password"
											prepend-icon="mdi-lock"
											type="password"
											:rules="passwordRules"
										></v-text-field>
									</v-col>
									<v-col md="12" sm="12">
										<v-text-field solo
										v-model="confirmPassword"
										label="Подтвердите новый пароль"
										name="confirmPassword"
										prepend-icon="mdi-lock"
										type="password"
										:rules="confirmPasswordRules"
										></v-text-field>
									</v-col>
								</v-row>
							</v-card-text>

							<v-divider></v-divider>

							<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="primary"
								text
								@click="changePass = false"
							>
								Отменить
							</v-btn>
							<v-btn
								color="primary"
								text
								@click="confirmChangePass"
							>
								Изменить
							</v-btn>
							</v-card-actions>
						</v-card>
						</v-dialog>
						</v-col>
					</v-row>
					
					</v-list-item>
				</v-list>
				</v-menu>
				
				
			</div>
			<!--<div class="header__buttons">
				<v-btn><i class="fas fa-arrow-down"></i></v-btn>
			</div>-->
		</div>
	</div>
</template>

<script>
//import {DoctorService} from '@/_services/doctor.service';
//const doctorService = new DoctorService;
import {userService} from '@/_services/user.service';
import NotificationService from '@/_services/notification.service';
import moment from 'moment';
export default {
	name: "Header",
	data() {
		return {
			activate: true,
			items: [{
                icon: "<v-icon>mdi-domain</v-icon>",
                title: "",
                link: "/doctor-page/profile"
			},
			],
			langs: [
				{
					name:'Русский',
					flag:"🇷🇺",
					localName: 'ru'
				}, 
				{
					name:'Кыргызский',
					flag:"🇰🇬",
					localName: 'kg'
				},
			],
			userAvatar:'',
			changePass:false,
			password: '',
			confirmPassword: '',
			passwordRules: [
				(value) => !!value || 'Введите новый пароль',
				(value) => (value && value.length >= 6) || 'минимум 6 символов',
			],
			confirmPasswordRules: [
				(value) => !!value || 'Подтвердите пароль',
				(value) =>
				value === this.password || 'Пароли не совпaдают, проверьте правильности.',
			],
			data: {
				isReset: false,
				newPassword: '',
			},
			notificationCount:0,
			notifications:[],
			item:{},
			avatarHas: false
		}
	},
	computed: {
		loggedIn() {
			return this.$store.state.account.user;
		},
	},
	mounted() {
	},
    created(){
	},
	methods:{
		confirmChangePass(){
           this.data.isReset = false;
           this.data.newPassword = this.password;
            //console.log(this.password);
            userService.changePass(this.loggedIn.user_id, this.data).then(()=> {
                   this.changePass =false;
                   this.$toastr.s("Пароль успешно изменён!");
               }).catch(err=>{console.log(err)});
        },
		getAllNotifications(){
			NotificationService.getAllNotificationByUserId(this.loggedIn.user_id,'UNREAD').then(res=>{
				//console.log(res);
				if(res.list){
					this.notifications = res.list.map((item)=>{
						item.date = moment(item.createdAt.slice(0,10)).format('DD.MM.YYYY');
						item.time = item.createdAt.slice(10,16);
						return item;
					});
					//console.log(this.notifications)
					this.notificationCount = res.totalCount;
				}
				
			}).catch(err=>{console.log(err)});
		},
		readAllNotifications()
		{
			NotificationService.makeAllRead(this.loggedIn.user_id).then(()=>{
				//console.log(res)
				this.getAllNotifications();
				this.$toastr.s("Все уведомлении прочитаны!");
			}).catch(err=>{console.log(err)});
		},	
		getDoctorAvatar(){
			userService.getById(this.loggedIn.user_id).then(res=>
			{
				if (res.avatar === null){
					this.avatarHas = false;
					//this.userAvatar = '../../assets/male.png'
					//  	if(res.gender === 2){
					//  		this.userAvatar = '../../assets/female.png'
					// 	}
					//  else
					//  {
					// 	this.userAvatar = '../../assets/male.png'
					//  }
				}
				else
				{
					this.avatarHas = true;
					this.userAvatar = res.avatar;
				}
			
				this.loading= false;
			}).catch(err=>console.log(err));
        },
	}
}
</script>

<style lang="scss" scoped>
.header {
	font-family: Roboto, sans-serif;
	font-style: normal;
    margin-left: 230px;
	font-weight: 300;
	background: #ffffff;
	height: 84px;
	display: flex;
	align-items: center;
	position: fixed;
    color:black;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1;
    -webkit-box-shadow: inset 0 0 6px #cbcdd1;
    &__search{
        background:#FAFBFD;
        margin:10px;
        width:600px;
        margin-right:auto;
        
    }
	&__appcreate{
		color:#fff;
		margin:10px;
		margin-right:auto;
		&__text{
		color:#fff;}
	}
	&__bookmarks{
		margin-left:25px;
	}
    &__notification{
        
        margin:30px;
		&__icon{
			color:#003df3;
		}
        &__badge{
			padding:5px;
			color:#414d55
		}
		&__list{
			width:400px;
			&__icon{
				margin-top:15px;
			}
			&__content{
				
				&__title{
					font-size:16px;
					font-weight:500;
					margin-left:5px;
					&__time{
						margin-top:5px;
						font-size:11px;
						color:#B1B6B9;
					}
				}
				&__body{
					margin-left:7px;
					color:#B1B6B9;
					font-weight:500
					
				}
			}
			&__btn{
				margin:0 auto;
			}
		}
    }
	
    &__avatar{
        margin-right:20px;
    }
	
    &__language{
		border: 2px solid #e3e2dc;
		border-radius:7px;
		padding:5px;
		align-items: center;
		display: inline-flex;
		flex: 0 0 auto;
		font-weight: 500;
		letter-spacing: 0.0892857143em;
		justify-content: center;
		outline: 0;
		position: relative;
		text-decoration: none;
		text-indent: 0.0892857143em;
		text-transform: uppercase;
		transition-duration: 0.28s;
		transition-property: box-shadow, transform, opacity;
		transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		vertical-align: middle;
		white-space: nowrap;
	}

	&__user {
		min-width: 200px;
		
		font-size: 18px;
		line-height: 21px;
		text-align: right;
		letter-spacing: 0.01em;
		color: #070505;
		margin-right: 40px;

		div:last-child {
			letter-spacing: 0.01em;
			color: #000000;
			margin-top: 5px;
			text-transform: uppercase;
			font-size: 16px;
		}
	}
    &__buttons{
        width:30px;
        margin-right:40px;
        background: #ffff;
        -webkit-box-shadow: inset 0 0 6px #ffffff;
    }
}
.avatar{
    border-radius:10px;
}
.header1 {
	font-family: Roboto, sans-serif;
	font-style: normal;
    margin-left: 230px;
	font-weight: 300;
	background: #2662F0;
	height: 84px;
	display: flex;
	align-items: center;
	position: fixed;
    color:black;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1;
    -webkit-box-shadow: inset 0 0 6px #2662F0;
    &__search{
        background:#FAFBFD;
        margin:10px;
        width:600px;
        margin-right:auto;
        
    }
    &__avatar{
        margin-right:20px;
		margin-left:5px;
    }
	&__modal{
		background-color:#2662F0;
		color:#fff
	}
    

	&__user {
		min-width: 200px;
		
		font-size: 18px;
		line-height: 21px;
		text-align: right;
		letter-spacing: 0.01em;
		color: #070505;
		margin-right: 40px;

		div:last-child {
			letter-spacing: 0.01em;
			color: #000000;
			margin-top: 5px;
			text-transform: uppercase;
			font-size: 16px;
		}
	}
    &__buttons{
        width:30px;
        margin-right:40px;
        background: #ffff;
        -webkit-box-shadow: inset 0 0 6px #ffffff;
    }
}
.avatar{
	object-fit:cover;
}
</style>

import { BaseApiService } from '@/_services/baseApi.service';

export class NotificationService extends BaseApiService {
    constructor() {
        super();
    }

    getNotificationById(notification_id) {
        const url = `${process.env.VUE_APP_BASE_URL}/v1/notification/${notification_id}`;
        return this.sendGetRequest(url);
    }
    getAllNotificationByUserId(user_id,status) {
        const url = `${process.env.VUE_APP_BASE_URL}/v1/notification/user-interface/${user_id}?status=${status}&offset=0&limit=20`;
        return this.sendGetRequest(url);
    }
    makeAllRead(userId) {
        const url = `${process.env.VUE_APP_BASE_URL}/v1/notification/make-all-read?userId=${userId}`;
        return this.sendPostRequest(url);
    }
    makeRead(notificationId) {
        const url = `${process.env.VUE_APP_BASE_URL}/v1/notification/make-read?userId=${notificationId}`;
        return this.sendPostRequest(url);
    }

    deleteAllNotifications(userId) {
        const url = `${process.env.VUE_APP_BASE_URL}/v1/notification/delete-all?userId=${userId}`;
        return this.sendDeleteRequest(url);
    }
}

export default new NotificationService();